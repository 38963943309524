import React from "react";
import "intl";
import { IntlProvider } from "react-intl";
import Footer from "../Footer";
import HeroPart from "../HeroPart";
import HeroImage from "../HeroPart/HeroImage";
import NavigationBar from "../NavigationBar";
import useLanguageInformation from "../../hooks/useLanguageInformation";

interface Props {
  isHeroVisible?: boolean;
}

const BaseLayout: React.FC<Props> = ({ children, isHeroVisible }) => {
  const languageInformation = useLanguageInformation();

  return (
    <IntlProvider
      locale={languageInformation.currentLanguageKey}
      messages={languageInformation.currentTranslation.i18n}
    >
      <header className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <NavigationBar />
            {isHeroVisible && (
              <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <HeroPart />
              </div>
            )}
          </div>
        </div>
        {isHeroVisible && <HeroImage />}
      </header>
      <main>{children}</main>
      <Footer />
    </IntlProvider>
  );
};

export default BaseLayout;
